import axiosInstance from './axios';

interface TwoFactorActivation {
  method: string;
  status: string;
  url: string;
}

interface AccountSettings {
  is_2fa_enabled: boolean | null;
  is_email_verified: boolean | null;
  is_kyc_verified: boolean | null;
  is_notification_enabled: boolean | null;
  is_phone_verified: boolean | null;
  is_password_set: boolean | null;
  is_verification_email_sent: boolean | null;
  two_factor_activations: TwoFactorActivation[];
}

interface Address {
  // Add address properties as needed
  // Since it's marked as null | object but properties weren't specified
  [key: string]: any;
}

interface SocialAccount {
  platform: string;
  username: string;
  icon: string;
}

interface EmailVerificationResponse {
  message: string;
  status: string;
}

interface VerifyEmailRequest {
  code: string;
  method: string;
  token: string | null;
}

interface TwoFactorRegistrationRequest {
  email: string;
  method: string;
}

interface TwoFactorActivationRequest {
  code: string;
  method: string;
}

interface TwoFactorResponse {
  message: string;
  status: string;
}

interface TwoFactorCancellationRequest {
  code: string;
  method: string;
}

interface PasswordUpdateRequest {
  current_password: string | null;
  new_password: string;
  two_factor_authentication: TwoFactorCancellationRequest;
}

interface NotificationSettingsRequest {
  enabled: boolean;
}

interface DeleteAccountRequest {
  current_password: string | null;
  two_factor_authentication: {
    code: string;
    method: string;
  } | null;
}

interface ContactRequest {
  name: string;
  email: string;
  message: string;
}

export interface User {
  user_id: string;
  first_name: string | null;
  last_name: string | null;
  other_names: string | null;
  email: string | null;
  phone: string | null;
  address: Address | null;
  date_created: string;
  date_updated: string | null;
  last_seen: string | null;
  social_accounts: SocialAccount[];
  account_settings: AccountSettings | null;
  two_factor_authentication: TwoFactorCancellationRequest;
}

export const userService = {
  async getUserById(userId: string): Promise<User> {
    try {
      const response = await axiosInstance.get(`/api/uss-alt/users/${userId}`, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching user:", error);
      throw error;
    }
  },

  async updateUser(userId: string, userData: Partial<User>): Promise<User> {
    try {
      const response = await axiosInstance.put(
        `/api/uss-alt/users/${userId}`,
        userData,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error updating user:", error);
      throw error;
    }
  },

  async sendVerificationEmail(userId: string): Promise<EmailVerificationResponse> {
    try {
      const response = await axiosInstance.post(
        `/api/uss-alt/users/${userId}/verification-email`,
        {},
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error sending verification email:", error);
      throw error;
    }
  },

  async verifyEmail(userId: string, verificationData: VerifyEmailRequest): Promise<EmailVerificationResponse> {
    try {
      const response = await axiosInstance.post(
        `/api/uss-alt/users/${userId}/email-verification`,
        verificationData,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error verifying email:", error);
      throw error;
    }
  },

  async register2FA(userId: string, data: TwoFactorRegistrationRequest): Promise<TwoFactorResponse> {
    try {
      const response = await axiosInstance.post(
        `/api/uss-alt/users/${userId}/settings/2fa/registration`,
        data,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error registering 2FA:", error);
      throw error;
    }
  },

  async activate2FA(userId: string, data: TwoFactorActivationRequest): Promise<TwoFactorResponse> {
    try {
      const response = await axiosInstance.post(
        `/api/uss-alt/users/${userId}/settings/2fa/activation`,
        data,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error activating 2FA:", error);
      throw error;
    }
  },

  async cancel2FA(userId: string, data: TwoFactorCancellationRequest): Promise<TwoFactorResponse> {
    try {
      const response = await axiosInstance.post(
        `/api/uss-alt/users/${userId}/settings/2fa/cancellation`,
        data,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error cancelling 2FA:", error);
      throw error;
    }
  },

  async updatePassword(userId: string, data: PasswordUpdateRequest): Promise<{ message: string }> {
    try {
      const response = await axiosInstance.patch(
        `/api/uss-alt/users/${userId}/password`,
        data,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error updating password:", error);
      throw error;
    }
  },

  async get2FAOTP(userId: string, method: string): Promise<{ message: string }> {
    try {
      const response = await axiosInstance.post(
        `/api/uss-alt/users/${userId}/settings/2fa/otp`,
        { method },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error getting 2FA OTP:", error);
      throw error;
    }
  },

  async resend2FAOTP(userId: string, method: string): Promise<{ message: string }> {
    try {
      const response = await axiosInstance.post(
        `/api/uss-alt/users/${userId}/settings/2fa/otp/resend`,
        { method },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error resending 2FA OTP:", error);
      throw error;
    }
  },

  async updateNotificationSettings(userId: string, enabled: boolean): Promise<{ message: string }> {
    try {
      const response = await axiosInstance.patch(
        `/api/uss-alt/users/${userId}/settings/notifications`,
        { enabled },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error updating notification settings:", error);
      throw error;
    }
  },

  async deleteAccount(userId: string, data: DeleteAccountRequest): Promise<{ message: string }> {
    try {
      const response = await axiosInstance.delete(`/api/uss-alt/users/${userId}`, {
        data,
        headers: {
          'Content-Type': 'application/json'
        }
      });
      return response.data;
    } catch (error) {
      console.error("Error deleting account:", error);
      throw error;
    }
  },

  async verifyEmailFromLink(userId: string, code: string, token: string): Promise<EmailVerificationResponse> {
    try {
      const response = await axiosInstance.post(
        `/api/uss/users/${userId}/email-verification`,
        {
          code,
          method: 'email',
          token
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error verifying email:", error);
      throw error;
    }
  },

  async sendContactEmail(data: ContactRequest): Promise<{ message: string }> {
    try {
      const response = await axiosInstance.post(
        '/api/uss/contact',
        data,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error sending contact email:", error);
      throw error;
    }
  }
}; 