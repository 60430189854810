import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { userService, User } from "@/services/api/userService";
import { getUserIdFromToken } from "@/utils/jwt";

interface UserState {
  currentUser: User | null;
  loadingStates: {
    fetchUser: boolean;
    updateUser: boolean;
    updatePassword: boolean;
    verifyEmail: boolean;
    twoFactorAuth: boolean;
    resendOTP: boolean;
    notifications: boolean;
    contact: boolean;
  };
  error: string | null;
}

const initialState: UserState = {
  currentUser: null,
  loadingStates: {
    fetchUser: false,
    updateUser: false,
    updatePassword: false,
    verifyEmail: false,
    twoFactorAuth: false,
    resendOTP: false,
    notifications: false,
    contact: false,
  },
  error: null,
};

export const fetchCurrentUser = createAsyncThunk(
  "user/fetchCurrentUser",
  async (_, { rejectWithValue }) => {
    try {
      const userId = getUserIdFromToken();
      if (!userId) throw new Error("No user ID found");
      return await userService.getUserById(userId);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateCurrentUser = createAsyncThunk(
  "user/updateCurrentUser",
  async (userData: Partial<User>, { rejectWithValue }) => {
    try {
      const userId = getUserIdFromToken();
      if (!userId) throw new Error("No user ID found");
      return await userService.updateUser(userId, userData);
    } catch (error) {
      return rejectWithValue("Failed to update user details" + error);
    }
  }
);

export const sendVerificationEmail = createAsyncThunk(
  "user/sendVerificationEmail",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const userId = getUserIdFromToken();
      if (!userId) throw new Error("No user ID found");

      const response = await userService.sendVerificationEmail(userId);

      await dispatch(fetchCurrentUser());

      return response;
    } catch (error) {
      return rejectWithValue("Failed to send verification email" + error);
    }
  }
);

export const verifyEmail = createAsyncThunk(
  "user/verifyEmail",
  async (
    verificationData: { code: string; method: string },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const userId = getUserIdFromToken();
      if (!userId) throw new Error("No user ID found");

      // First verify the email
      const verificationResponse = await userService.verifyEmail(
        userId,
        verificationData
      );

      // Then fetch updated user details
      await dispatch(fetchCurrentUser());

      return verificationResponse;
    } catch (error) {
      return rejectWithValue("Failed to verify email" + error);
    }
  }
);

export const register2FA = createAsyncThunk(
  "user/register2FA",
  async (data: { email: string; method: string }, { rejectWithValue }) => {
    try {
      const userId = getUserIdFromToken();
      if (!userId) throw new Error("No user ID found");
      return await userService.register2FA(userId, data);
    } catch (error) {
      return rejectWithValue("Failed to register 2FA" + error);
    }
  }
);

export const activate2FA = createAsyncThunk(
  "user/activate2FA",
  async (
    data: { code: string; method: string },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const userId = getUserIdFromToken();
      if (!userId) throw new Error("No user ID found");

      // First activate 2FA
      const activationResponse = await userService.activate2FA(userId, data);

      // Then fetch updated user details to reflect new 2FA status
      await dispatch(fetchCurrentUser());

      return activationResponse;
    } catch (error) {
      return rejectWithValue("Failed to activate 2FA" + error);
    }
  }
);

export const cancel2FA = createAsyncThunk(
  "user/cancel2FA",
  async (
    data: { code: string; method: string },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const userId = getUserIdFromToken();
      if (!userId) throw new Error("No user ID found");

      const response = await userService.cancel2FA(userId, data);

      // Fetch updated user details after cancellation
      await dispatch(fetchCurrentUser());

      return response;
    } catch (error) {
      return rejectWithValue("Failed to cancel 2FA" + error);
    }
  }
);

export const updatePassword = createAsyncThunk(
  "user/updatePassword",
  async (
    data: {
      currentPassword: string | null;
      newPassword: string;
      twoFactorAuthentication: string | null;
    },
    { rejectWithValue }
  ) => {
    try {
      const userId = getUserIdFromToken();
      if (!userId) throw new Error("No user ID found");

      return await userService.updatePassword(userId, {
        current_password: data.currentPassword,
        new_password: data.newPassword,
        two_factor_authentication: data.twoFactorAuthentication,
      });
    } catch (error) {
      return rejectWithValue("Failed to update password" + error);
    }
  }
);

export const get2FAOTP = createAsyncThunk(
  "user/get2FAOTP",
  async (method: string, { rejectWithValue }) => {
    try {
      const userId = getUserIdFromToken();
      if (!userId) throw new Error("No user ID found");

      return await userService.get2FAOTP(userId, method);
    } catch (error) {
      return rejectWithValue("Failed to get 2FA OTP" + error);
    }
  }
);

export const resend2FAOTP = createAsyncThunk(
  "user/resend2FAOTP",
  async (method: string, { rejectWithValue }) => {
    try {
      const userId = getUserIdFromToken();
      if (!userId) throw new Error("No user ID found");

      return await userService.resend2FAOTP(userId, method);
    } catch (error) {
      return rejectWithValue("Failed to resend 2FA OTP" + error);
    }
  }
);

export const updateNotificationSettings = createAsyncThunk(
  "user/updateNotificationSettings",
  async (enabled: boolean, { rejectWithValue, dispatch }) => {
    try {
      const userId = getUserIdFromToken();
      if (!userId) throw new Error("No user ID found");

      const response = await userService.updateNotificationSettings(
        userId,
        enabled
      );

      // Fetch updated user details after updating notifications
      await dispatch(fetchCurrentUser());

      return response;
    } catch (error) {
      return rejectWithValue("Failed to update notification settings" + error);
    }
  }
);

export const deleteAccount = createAsyncThunk(
  "user/deleteAccount",
  async (
    data: {
      currentPassword: string | null;
      twoFactorAuthentication: { code: string; method: string } | null;
    },
    { rejectWithValue }
  ) => {
    try {
      const userId = getUserIdFromToken();
      if (!userId) throw new Error("No user ID found");

      return await userService.deleteAccount(userId, {
        current_password: data.currentPassword,
        two_factor_authentication: data.twoFactorAuthentication,
      });
    } catch (error) {
      return rejectWithValue("Failed to delete account" + error);
    }
  }
);

export const verifyEmailFromLink = createAsyncThunk(
  "user/verifyEmailFromLink",
  async (
    { userId, code, token }: { userId: string; code: string; token: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await userService.verifyEmailFromLink(
        userId,
        code,
        token
      );
      return response;
    } catch (error) {
      return rejectWithValue("Failed to verify email: " + error);
    }
  }
);

export const sendContactEmail = createAsyncThunk(
  "user/sendContactEmail",
  async (data: {
    name: string;
    email: string;
    message: string;
  }, { rejectWithValue }) => {
    try {
      return await userService.sendContactEmail(data);
    } catch (error) {
      return rejectWithValue("Failed to send contact email: " + error);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearUser: (state) => {
      state.currentUser = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch user loading states
      .addCase(fetchCurrentUser.pending, (state) => {
        state.loadingStates.fetchUser = true;
        state.error = null;
      })
      .addCase(fetchCurrentUser.fulfilled, (state, action) => {
        state.loadingStates.fetchUser = false;
        state.currentUser = action.payload;
      })
      .addCase(fetchCurrentUser.rejected, (state, action) => {
        state.loadingStates.fetchUser = false;
        state.error = action.payload as string;
      })

      // Update user loading states
      .addCase(updateCurrentUser.pending, (state) => {
        state.loadingStates.updateUser = true;
        state.error = null;
      })
      .addCase(updateCurrentUser.fulfilled, (state, action) => {
        state.loadingStates.updateUser = false;
        state.currentUser = action.payload;
      })
      .addCase(updateCurrentUser.rejected, (state, action) => {
        state.loadingStates.updateUser = false;
        state.error = action.payload as string;
      })

      // Two-factor authentication loading states
      .addCase(activate2FA.pending, (state) => {
        state.loadingStates.twoFactorAuth = true;
        state.error = null;
      })
      .addCase(activate2FA.fulfilled, (state) => {
        state.loadingStates.twoFactorAuth = false;
      })
      .addCase(activate2FA.rejected, (state, action) => {
        state.loadingStates.twoFactorAuth = false;
        state.error = action.payload as string;
      })

      // Resend OTP loading states
      .addCase(resend2FAOTP.pending, (state) => {
        state.loadingStates.resendOTP = true;
        state.error = null;
      })
      .addCase(resend2FAOTP.fulfilled, (state) => {
        state.loadingStates.resendOTP = false;
      })
      .addCase(resend2FAOTP.rejected, (state, action) => {
        state.loadingStates.resendOTP = false;
        state.error = action.payload as string;
      })

      // Send verification email loading states
      .addCase(sendVerificationEmail.pending, (state) => {
        state.loadingStates.verifyEmail = true;
        state.error = null;
      })
      .addCase(sendVerificationEmail.fulfilled, (state) => {
        state.loadingStates.verifyEmail = false;
      })
      .addCase(sendVerificationEmail.rejected, (state, action) => {
        state.loadingStates.verifyEmail = false;
        state.error = action.payload as string;
      })

      // Verify email loading states
      .addCase(verifyEmail.pending, (state) => {
        state.loadingStates.verifyEmail = true;
        state.error = null;
      })
      .addCase(verifyEmail.fulfilled, (state) => {
        state.loadingStates.verifyEmail = false;
      })
      .addCase(verifyEmail.rejected, (state, action) => {
        state.loadingStates.verifyEmail = false;
        state.error = action.payload as string;
      })

      // Register 2FA loading states
      .addCase(register2FA.pending, (state) => {
        state.loadingStates.twoFactorAuth = true;
        state.error = null;
      })
      .addCase(register2FA.fulfilled, (state) => {
        state.loadingStates.twoFactorAuth = false;
      })
      .addCase(register2FA.rejected, (state, action) => {
        state.loadingStates.twoFactorAuth = false;
        state.error = action.payload as string;
      })

      // Cancel 2FA loading states
      .addCase(cancel2FA.pending, (state) => {
        state.loadingStates.twoFactorAuth = true;
        state.error = null;
      })
      .addCase(cancel2FA.fulfilled, (state) => {
        state.loadingStates.twoFactorAuth = false;
      })
      .addCase(cancel2FA.rejected, (state, action) => {
        state.loadingStates.twoFactorAuth = false;
        state.error = action.payload as string;
      })

      // Update password loading states
      .addCase(updatePassword.pending, (state) => {
        state.loadingStates.updatePassword = true;
        state.error = null;
      })
      .addCase(updatePassword.fulfilled, (state) => {
        state.loadingStates.updatePassword = false;
      })
      .addCase(updatePassword.rejected, (state, action) => {
        state.loadingStates.updatePassword = false;
        state.error = action.payload as string;
      })

      // Get 2FA OTP loading states
      .addCase(get2FAOTP.pending, (state) => {
        state.loadingStates.fetchUser = true;
        state.error = null;
      })
      .addCase(get2FAOTP.fulfilled, (state) => {
        state.loadingStates.fetchUser = false;
      })
      .addCase(get2FAOTP.rejected, (state, action) => {
        state.loadingStates.fetchUser = false;
        state.error = action.payload as string;
      })

      // Update notification settings loading states
      .addCase(updateNotificationSettings.pending, (state) => {
        state.loadingStates.notifications = true;
        state.error = null;
      })
      .addCase(updateNotificationSettings.fulfilled, (state) => {
        state.loadingStates.notifications = false;
      })
      .addCase(updateNotificationSettings.rejected, (state, action) => {
        state.loadingStates.notifications = false;
        state.error = action.payload as string;
      })

      // Delete account loading states
      .addCase(deleteAccount.pending, (state) => {
        state.loadingStates.fetchUser = true;
        state.error = null;
      })
      .addCase(deleteAccount.fulfilled, (state) => {
        state.loadingStates.fetchUser = false;
      })
      .addCase(deleteAccount.rejected, (state, action) => {
        state.loadingStates.fetchUser = false;
        state.error = action.payload as string;
      })

      // Verify email from link loading states
      .addCase(verifyEmailFromLink.pending, (state) => {
        state.loadingStates.verifyEmail = true;
        state.error = null;
      })
      .addCase(verifyEmailFromLink.fulfilled, (state) => {
        state.loadingStates.verifyEmail = false;
      })
      .addCase(verifyEmailFromLink.rejected, (state, action) => {
        state.loadingStates.verifyEmail = false;
        state.error = action.payload as string;
      })

      // Send contact email loading states
      .addCase(sendContactEmail.pending, (state) => {
        state.loadingStates.contact = true;
        state.error = null;
      })
      .addCase(sendContactEmail.fulfilled, (state) => {
        state.loadingStates.contact = false;
      })
      .addCase(sendContactEmail.rejected, (state, action) => {
        state.loadingStates.contact = false;
        state.error = action.payload as string;
      });
  },
});

export const { clearUser } = userSlice.actions;
export default userSlice.reducer;
